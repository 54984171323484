import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import confetti from "canvas-confetti";
import { showError, showSuccess } from "../components/commonFuncs";
import { Routes, Route, useNavigate, redirect } from "react-router-dom";
import { TbSquare, TbX, TbSquareDotFilled } from "react-icons/tb";

import axios from "axios";

import rixos_logo from "../assets/rixos_logo.png";
import buyuk_cekilis_text from "../assets/buyuk_cekilis_text.png";
import next from "../assets/next.png";
import prev from "../assets/prev.png";

import hediye_1 from "../assets/hediye_1.png";
import hediye_2 from "../assets/hediye_2.png";
import hediye_3 from "../assets/hediye_3.png";
import hediye_4 from "../assets/hediye_4.png";
import hediye_5 from "../assets/hediye_5.png";
import hediye_6 from "../assets/hediye_6.png";
import hediye_7 from "../assets/hediye_7.png";
import hediye_8 from "../assets/hediye_8.png";
import hediye_9 from "../assets/hediye_9.png";
import hediye_10 from "../assets/hediye_10.png";
import hediye_11 from "../assets/hediye_11.png";
import hediye_12 from "../assets/hediye_12.png";

function Cekilis() {
  const navigate = useNavigate();

  const [hediyes, sethediyes] = useState([
    hediye_1,
    hediye_2,
    hediye_3,
    hediye_4,
    hediye_5,
    hediye_6,
    hediye_7,
    hediye_8,
    hediye_9,
    hediye_10,
    hediye_11,
    hediye_12,
  ]);
  const [cekilis2_ad, setcekilis2_ad] = useState("");
  const [cekilis3_ad, setcekilis3_ad] = useState("");
  const [cekilis4_ad, setcekilis4_ad] = useState("");
  const [cekilis5_ad, setcekilis5_ad] = useState("");
  const [cekilis6_ad, setcekilis6_ad] = useState("");

  const [cekilis1_win_ad, setcekilis1_win_ad] = useState("");
  const [cekilis2_win_ad, setcekilis2_win_ad] = useState("");
  const [cekilis3_win_ad, setcekilis3_win_ad] = useState("");
  const [cekilis4_win_ad, setcekilis4_win_ad] = useState("");
  const [cekilis5_win_ad, setcekilis5_win_ad] = useState("");
  const [cekilis6_win_ad, setcekilis6_win_ad] = useState("");

  const [cekilis1_win_code, setcekilis1_win_code] = useState("0");
  const [cekilis2_win_code, setcekilis2_win_code] = useState("0");
  const [cekilis3_win_code, setcekilis3_win_code] = useState("0");
  const [cekilis4_win_code, setcekilis4_win_code] = useState("0");
  const [cekilis5_win_code, setcekilis5_win_code] = useState("0");
  const [cekilis6_win_code, setcekilis6_win_code] = useState("0");

  const [selected, setselected] = useState(1);

  const [countsn, setcountsn] = useState(0);
  const [countnumber, setcountnumber] = useState(1);

  const [bitti, setbitti] = useState(false);
  const [show_anim, setshow_anim] = useState(false);

  const [hediye_index, sethediye_index] = useState(0);
  const [hediyeler, sethediyeler] = useState([]);
  const [users, setusers] = useState([]);
  const [hediye_kazanan_sayi, sethediye_kazanan_sayi] = useState(-1);
  const [hediye_kazananlar, sethediye_kazananlar] = useState([]);

  useEffect(() => {
    gethediyeler();
    getusers();
  }, []);

  const gethediyeler = () => {
    axios
      .get("https://api.muchmorewall.site/hediyeler")
      .then(function (response) {
        console.log(response.data);
        sethediyeler(response.data.hediyeler);
      });
  };
  const getusers = () => {
    axios.get("https://api.muchmorewall.site/users").then(function (response) {
      //console.log(response.data.users);

      setusers(response.data.users);
    });
  };

  const goprev = () => {
    if (hediye_index === 0) {
      return;
    }
    if (countsn > 1) {
      return;
    }
    sethediye_index(hediye_index - 1);
    setcountsn(0);
    setshow_anim(false);
    setbitti(false);
    sethediye_kazanan_sayi(-1);
  };
  const gonext = () => {
    if (hediye_index === hediyeler.length - 1) {
      return;
    }
    if (countsn > 1) {
      return;
    }
    sethediye_index(hediye_index + 1);
    setcountsn(0);
    setshow_anim(false);
    setbitti(false);

    sethediye_kazanan_sayi(-1);
  };

  useEffect(() => {
    if (hediyeler.length === 0) {
      return;
    }
    gethediyedetails();
  }, [hediye_index, hediyeler]);

  const gethediyedetails = () => {
    axios
      .get(
        "https://api.muchmorewall.site/hediye?id=" + hediyeler[hediye_index].id
      )
      .then(function (response) {
        console.log(response.data);

        sethediye_kazanan_sayi(response.data.kazanan_sayi);
        sethediye_kazananlar(response.data.kazananlar);
      });
  };

  const [interval, setinterval] = useState(null);

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const counntt = () => {
    console.log("counting");

    setshow_anim(true);

    const iin = setInterval(function () {
      //console.log("hello");

      setcountnumber(getRandomArbitrary(0, users.length - 1));

      setcountsn((...countsn) => parseInt(countsn) + 1);
    }, 100);

    setinterval(iin);

    //
  };

  useEffect(() => {
    //console.log(countsn);
    if (countsn > 50) {
      if (countsn % 30 === 0) {
        console.log("çekti----");

        let url =
          "https://api.muchmorewall.site/kazanan_belirle?id=" +
          hediyeler[hediye_index].id;
        console.log(url);

        axios.get(url).then(function (response) {
          console.log("sonuç döndü", response.data);

          if (
            parseInt(response.data.toplam_sayi) ===
            parseInt(response.data.cekilmis_sayi)
          ) {
            console.log("bitti");

            setbitti(true);
            setcountsn(0);
            clearInterval(interval);
          }

          let kazananlar_yeni = [...hediye_kazananlar];
          kazananlar_yeni.push(response.data.kazanan_kisi);

          sethediye_kazananlar(kazananlar_yeni);

          //gethediyedetails()
        });
      }
    }

    /*
    if(bitti){
      clearInterval(interval);

      //sethediye_kazanan_sayi(countsn);

      let url = "https://api.muchmorewall.site/kazanan_belirle?id="+hediyeler[hediye_index].id
      axios.get(url).then(function (response) {
        gethediyedetails()
      })


     
    }
      */
  }, [countsn]);

  var defaults = { startVelocity: 35, spread: 360, ticks: 60, zIndex: 3 };

  useEffect(() => {
    if (bitti) {
      let interval2 = setInterval(function () {
        var particleCount = randomInRange(200, 300);

        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 300);

      setTimeout(() => clearInterval(interval2), 10000);
    }
  }, [bitti]);

  function getRandomArbitrary(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
  }

  return (
    <div className="cekilisbg flex flex-col items-center ">
      <img src={buyuk_cekilis_text} alt="logo" className="mt-10" width={740} />

      <img
        src={hediyes[hediye_index]}
        alt="logod"
        className="mt-6"
        style={{ height: 315 }}
      />

      <div className="flex items-center mt-2 gap-6">
        <img
          src={prev}
          alt="logo"
          className="cursor-pointer"
          onClick={goprev}
          width={50}
        />
        <div className="text-3xl text-center" style={{ width: "470px" }}>
          {hediyeler.length > 0 && hediyeler[hediye_index].ad}
        </div>
        <img
          src={next}
          alt="logo"
          className="cursor-pointer"
          onClick={gonext}
          width={50}
        />
      </div>

      <div>
        {hediye_kazanan_sayi < 0 ? (
          <></>
        ) : (
          <>
            {show_anim === true ? (
              <div className="text-6xl mt-20 text-center text-amber-400 ">
                {bitti ? "TEBRİK EDERİZ!" : users[countnumber].ad}
              </div>
            ) : hediye_kazananlar.length === 0 ? (
              <div
                onClick={() => counntt()}
                className="cekilis_button mt-10 mx-auto"
              ></div>
            ) : (
              <div className="text-6xl mt-20 text-center text-amber-400 bolderr  ">
                TEBRİK EDERİZ!
              </div>
            )}

            <div
              className={
                (hediye_kazananlar.length === 1
                  ? "justify-center"
                  : "justify-start") +
                " mt-14 flex flex-wrap gap-y-3 items-center  mx-auto"
              }
              style={{ width: "1200px" }}
            >
              {hediye_kazananlar.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="text-4xl whitespace-nowrap text-center"
                    style={{ width: "600px" }}
                  >
                    {item.ad}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Cekilis;
